import React, { useState } from 'react'
import { useDataProvider, useNotify, useRefresh } from 'react-admin'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import useStyles from '../styles/index'

const OfferHeaderSection = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const [saving, setSaving] = useState(false)
  // const [status, setStatus] = useState(props.record?.status)
  // console.log(props)

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/#/offer" onClick={handleClickBreacrumb}>
      Beni Offers
    </Link>,
    // <Link
    //   underline="hover"
    //   key="2"
    //   color="inherit"
    //   href="/material-ui/getting-started/installation/"
    //   onClick={handleClickBreacrumb}
    // >
    //   Core
    // </Link>,
    <Typography key="2" color="primary">
      Product
    </Typography>,
  ]

  const handleClickBreacrumb = () => {
    // console.log(props)
  }

  const StatBox = (props) => {
    const classes = useStyles()

    return (
      <Grid item xs={2} md={2}>
        <Box display="flex" flexDirection="column">
          <span className={classes.statValue}>{props.value || ''}</span>
          <span className={classes.statTitle}>{props.title}</span>
        </Box>
      </Grid>
    )
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" m="0px 0px 10px 0px">
        <span className={classes.productHeader}>{props.record?.name}</span>
      </Box>
      <Box display="flex" m="0px 0px 10px 0px">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Box>

      <Box display="flex">
        <Box flex={1} display="flex" alignItems="center" justifyContent="center">
          <img className={classes.primaryPhoto} src={props.record?.primary_photo?.url} alt="" />
        </Box>

        <Box flex={3} display="flex" p="10px 30px" flexDirection="column">
          <span className={classes.subHeader}>{props.record?.name}</span>
          <span className={classes.subHeaderSKU}>{props.record?.id}</span>
          <Box mt="20px">
            <Grid container spacing={2}>
              <StatBox title="PO Quantity" value={props.record?.moq} />
              <StatBox
                title="Investor Price"
                value={`$${
                  props.record?.investor_price_per_piece
                    ? props.record?.investor_price_per_piece / 100
                    : '-'
                }`}
              />
              <StatBox
                title="Target Investor Income ($)"
                value={`$${props.record?.estimated_ROI ? props.record?.estimated_ROI / 100 : '-'}`}
              />
              <StatBox
                title="Target Retail Price"
                value={`$${props.record?.retail_price ? props.record?.retail_price / 100 : '-'}`}
              />
              <StatBox
                title="Commission%"
                value={`${
                  props.record?.commission_fees ? props.record?.commission_fees / 100 : '-'
                }%`}
              />
            </Grid>
          </Box>
        </Box>
        <Box flex={1}>
          <span>{props.record?.status}</span>
          <Box display="flex" flexDirection="column">
            {saving && (
              <CircularProgress
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
            )}
            {props.record?.status === 'PENDING_APPROVAL' && (
              <Button
                style={{
                  border: 'solid 1px #CDCDCD',
                  borderRadius: '6px',
                  width: '200px',
                  marginTop: '5px',
                }}
                onClick={async () => {
                  try {
                    setSaving(true)
                    await dataProvider.update('offer', {
                      data: { id: props.record?.id, status: 'PRODUCTION' },
                    })
                    setSaving(false)
                    refresh()
                  } catch (error) {
                    console.log(error)
                    setSaving(false)
                    notify(error.message, { type: 'warning' })
                  }
                }}
              >
                Approve Production
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default OfferHeaderSection
